import React, { useState } from "react";

// tools
import styled from "styled-components";

// globals
import colors from "../../../globals/colors";

// const HISTORY_2022 = [{ month: "02", title: "스파크랩, VNTG Seed 투자유치" }];
const HISTORY_2022 = [
  { month1: "0", month2: "2", title: "스파크랩, VNTG Seed 투자유치" },
];
const HISTORY_2021 = [
  { month: "12", month1: "1", month2: "2", title: "스파크랩 18기 선정" },
  { month: "11", month1: "1", month2: "1", title: "BNK핀테크랩 4기 선정" },
  {
    month: "07",
    month1: "0",
    month2: "7",
    title:
      "중소벤처기업부 주관 스마트 서비스 지원사업 공급기업, 도입기업 동시 선정",
  },
  {
    month: "06",
    month1: "0",
    month2: "6",
    title: "한국콘텐츠진흥원 X 스파크랩 뉴콘텐츠랩 프로그램 선정",
  },
  {
    month: "05",
    month1: "0",
    month2: "5",
    title: "창업진흥원 초기창업패키지 선정 주관기관 : 숭실대학교",
  },
  {
    month: "04",
    month1: "0",
    month2: "4",
    title: "농협 NH디지털챌린지+ 5기 선정",
  },
  {
    month: "03",
    month1: "0",
    month2: "3",
    title: "신용보증기금 Start-Up Nest 9기 선정",
  },
];

const HISTORY_2020 = [
  {
    month: "12",
    month1: "1",
    month2: "2",
    title: "SC제일은행 핀테크 데모데이 3위 수상",
  },
  {
    month: "11",
    month1: "1",
    month2: "1",
    title: "SK텔레콤 True Innovation Young 1기 선정",
  },
  {
    month: "07",
    month1: "0",
    month2: "7",
    title: "신한 오픈이노베이션 프로그램 1기 선정",
  },
  {
    month: "06",
    month1: "0",
    month2: "6",
    title: "벤처기업협회 창업진흥원 비대면 서비스 공급 기업 선정",
  },
  {
    month: "05",
    month1: "0",
    month2: "5",
    title: "경기 START 프로그램 기업 선정(스파크랩X골드아크)",
  },
];

const History = ({ historyRef, innerWidth }) => {
  const [isThisYear, setIsThisYear] = useState(true);
  const [year, setYear] = useState(2022);
  const titleFontSize =
    innerWidth > 1300 ? 42 : 42 - 8 * ((1300 - innerWidth) / (1300 - 744));
  // const titleFontSizeTM =
  //   innerWidth > 744 ? 34 : 34 - 10 * ((744 - innerWidth) / (744 - 375));
  const titleFontSizeTM =
    innerWidth > 744 ? 34 : 34 - 14 * ((744 - innerWidth) / (744 - 375));
  const titleTopMarginTM =
    innerWidth > 744 ? 100 : 100 - 40 * ((744 - innerWidth) / (744 - 375));
  const yearFontSize =
    innerWidth > 1300 ? 32 : 32 - 8 * ((1300 - innerWidth) / (1300 - 744));
  const yearFontSizeTM =
    innerWidth > 744 ? 24 : 24 - 4 * ((744 - innerWidth) / (744 - 375));
  const yearSideMarginTM =
    innerWidth > 744 ? 20 : 20 - 14 * ((744 - innerWidth) / (744 - 375));
  const containerSidePadding =
    innerWidth > 1300 ? 155 : 155 - 125 * ((1300 - innerWidth) / (1300 - 744));
  const containerMinHeight =
    innerWidth > 744 ? 468 : 468 - 14 * ((744 - innerWidth) / (744 - 375));
  const sideMargin =
    innerWidth > 1454 ? 120 : 120 - 70 * ((1454 - innerWidth) / (1454 - 744));
  const sideMarginTM =
    innerWidth > 744 ? 50 : 50 - 30 * ((744 - innerWidth) / (744 - 375));
  const containerTopMarginTM =
    innerWidth > 744 ? 180 : 180 - 60 * ((744 - innerWidth) / (744 - 375));
  const lineTopMarginTM =
    innerWidth > 744 ? 20 : 20 - 4 * ((744 - innerWidth) / (744 - 375));
  const lineSideMarginTM =
    innerWidth > 744 ? 30 : 30 - 14 * ((744 - innerWidth) / (744 - 375));
  const monthFontTM =
    innerWidth > 744 ? 20 : 20 - 4 * ((744 - innerWidth) / (744 - 375));
  const titleContentTopMarginTM =
    innerWidth > 744 ? 4 : 4 - 4 * ((744 - innerWidth) / (744 - 375));
  const contentTopMarginTM =
    innerWidth > 744 ? 30 : 30 - 10 * ((744 - innerWidth) / (744 - 375));
  const yearMarginTopTM =
    innerWidth > 744 ? 60 : 60 - 20 * ((744 - innerWidth) / (744 - 375));

  const onChangeYear = (year) => {
    setYear(year);
  };

  return (
    <Container
      ref={historyRef}
      containerSidePadding={containerSidePadding}
      sideMargin={sideMargin}
      sideMarginTM={sideMarginTM}
      containerTopMarginTM={containerTopMarginTM}
    >
      <Title
        titleFontSize={titleFontSize}
        titleFontSizeTM={titleFontSizeTM}
        titleTopMarginTM={titleTopMarginTM}
      >
        페이워크 팀이 이룬 성과들
      </Title>
      <YearWrapper
        yearFontSize={yearFontSize}
        yearFontSizeTM={yearFontSizeTM}
        yearMarginTopTM={yearMarginTopTM}
      >
        <ThisYear isThisYear={year === 2022} onClick={() => onChangeYear(2022)}>
          2022
        </ThisYear>
        <LastYear isLastYear={year === 2021} onClick={() => onChangeYear(2021)}>
          2021
        </LastYear>
        <BeforeLastYear
          isBeforeLastYear={year === 2020}
          onClick={() => onChangeYear(2020)}
        >
          2020
        </BeforeLastYear>
      </YearWrapper>
      <Line
        containerSidePadding={containerSidePadding}
        lineTopMarginTM={lineTopMarginTM}
        lineSideMarginTM={lineSideMarginTM}
      />

      <ContentsContainer containerMinHeight={containerMinHeight}>
        {year === 2022 &&
          HISTORY_2022.map((content, idx) => {
            return (
              <ContentsWrapper
                key={idx}
                containerSidePadding={containerSidePadding}
                lineSideMarginTM={lineSideMarginTM}
              >
                <Content contentTopMarginTM={contentTopMarginTM}>
                  <YearContent
                    monthFontTM={monthFontTM}
                    yearSideMarginTM={yearSideMarginTM}
                  >
                    <div>{content.month1}</div>
                    <div>{content.month2}</div>
                  </YearContent>
                  <TitleContent
                    titleContentTopMarginTM={titleContentTopMarginTM}
                  >
                    {content.title}
                  </TitleContent>
                </Content>
              </ContentsWrapper>
            );
          })}

        {year === 2021 &&
          HISTORY_2021.map((content, idx) => {
            return (
              <ContentsWrapper
                key={idx}
                containerSidePadding={containerSidePadding}
                lineSideMarginTM={lineSideMarginTM}
              >
                <Content contentTopMarginTM={contentTopMarginTM}>
                  <YearContent
                    monthFontTM={monthFontTM}
                    yearSideMarginTM={yearSideMarginTM}
                  >
                    <div>{content.month1}</div>
                    <div>{content.month2}</div>
                  </YearContent>
                  <TitleContent
                    titleContentTopMarginTM={titleContentTopMarginTM}
                  >
                    {content.title}
                  </TitleContent>
                </Content>
              </ContentsWrapper>
            );
          })}

        {year === 2020 &&
          HISTORY_2020.map((content, idx) => {
            return (
              <ContentsWrapper
                key={idx}
                containerSidePadding={containerSidePadding}
                lineSideMarginTM={lineSideMarginTM}
              >
                <Content contentTopMarginTM={contentTopMarginTM}>
                  <YearContent
                    monthFontTM={monthFontTM}
                    yearSideMarginTM={yearSideMarginTM}
                  >
                    <div>{content.month1}</div>
                    <div>{content.month2}</div>
                  </YearContent>
                  <TitleContent
                    titleContentTopMarginTM={titleContentTopMarginTM}
                  >
                    {content.title}
                  </TitleContent>
                </Content>
              </ContentsWrapper>
            );
          })}
      </ContentsContainer>
    </Container>
  );
};

export default History;

const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 150px 120px 0;
  flex-direction: column;
  background: #363742;
  border-radius: 50px;
  width: 1200px;

  @media ${(props) => props.theme.tablet} {
    /* margin: ${(props) =>
      `${props.containerTopMarginTM}px ${props.sideMarginTM}px 0`}; */
    margin: ${(props) => `${props.containerTopMarginTM}px 50px 0`};
  }

  @media ${(props) => props.theme.mobile} {
    margin: 120px 20px 0;
    padding-top: 60px;

    /* width: 320px; */
    width: auto;
    display: block;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: ${(props) => `${props.titleFontSize}px`};
  margin-top: 100px;
  font-weight: 600;

  @media ${(props) => props.theme.tablet} {
    font-size: ${(props) => `${props.titleFontSizeTM}px`};
    margin-top: ${(props) => `${props.titleTopMarginTM}px`};
  }

  @media ${(props) => props.theme.mobile} {
    font-size: 24px;
    margin-top: 0px;
    /* margin-top: 60px; */
  }
`;

const YearWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${(props) => `${props.yearFontSize}px`};
  font-weight: 600;
  margin-top: 60px;

  @media ${(props) => props.theme.tablet} {
    font-size: ${(props) => `${props.yearFontSizeTM}px`};
    margin-top: ${(props) => `${props.yearMarginTopTM}px`};
  }

  @media ${(props) => props.theme.mobile} {
    font-size: 20px;
    margin-top: 40px;
  }
`;

const ThisYear = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: ${(props) => (props.isThisYear ? `#346DFF` : `${colors.G4}`)};

  &:hover {
    color: #346dff;
  }
`;

const LastYear = styled.div`
  margin-left: 40px;
  cursor: pointer;
  color: ${(props) => (props.isLastYear ? `#346DFF` : `${colors.G4}`)};

  &:hover {
    color: #346dff;
  }
`;

const BeforeLastYear = styled.div`
  margin-left: 40px;
  cursor: pointer;
  color: ${(props) => (props.isBeforeLastYear ? `#346DFF` : `${colors.G4}`)};

  &:hover {
    color: #346dff;
  }
`;

const Line = styled.div`
  display: flex;
  height: 1px;
  background: ${colors.G4};
  margin-bottom: 12px;
  margin: ${(props) => `20px ${props.containerSidePadding}px 0`};

  @media ${(props) => props.theme.tablet} {
    margin: ${(props) =>
      `${props.lineTopMarginTM}px ${props.lineSideMarginTM}px 0`};
  }

  @media ${(props) => props.theme.mobile} {
    margin: 16px 16px 0;
  }
`;

const ContentsContainer = styled.div`
  min-height: 460px;

  @media ${(props) => props.theme.tablet} {
    min-height: ${(props) => `${props.containerMinHeight}px`};
  }

  @media ${(props) => props.theme.mobile} {
    min-height: 454px;
  }
`;

const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => `${props.containerSidePadding}px`};

  @media ${(props) => props.theme.tablet} {
    margin-left: ${(props) => `${props.lineSideMarginTM}px`};
    margin-right: ${(props) => `${props.lineSideMarginTM}px`};
  }

  @media ${(props) => props.theme.mobile} {
    margin-left: 16px;
    margin-right: 16px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  color: #ffffff;
  margin-top: 30px;

  @media ${(props) => props.theme.tablet} {
    margin-top: ${(props) => `${props.contentTopMarginTM}px`};
  }

  @media ${(props) => props.theme.mobile} {
    margin-top: 20px;
  }
`;

const YearContent = styled.div`
  font-size: 20px;
  font-weight: 700;
  /* width: 43px; */
  width: 23px;
  margin-right: 20px;
  display: flex;
  justify-content: space-around;

  @media ${(props) => props.theme.tablet} {
    font-size: ${(props) => `${props.monthFontTM}px`};
    margin-right: ${(props) => `${props.yearSideMarginTM}px`};
  }

  @media ${(props) => props.theme.mobile} {
    font-size: 16px;
    margin-right: 6px;
  }
`;

const TitleContent = styled.div`
  font-size: 16px;
  margin-top: 2px;

  @media ${(props) => props.theme.tablet} {
    margin-top: ${(props) => `${props.titleContentTopMarginTM}px`};
  }

  @media ${(props) => props.theme.mobile} {
    margin-top: 0px;
  }
`;
