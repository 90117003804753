import React, { useEffect, useState } from "react";

//tool
import styled from "styled-components";

//components
import Header from "../../components/Header";
import Front from "./components/Front";
import Way from "./components/Way";
import Coworkers from "./components/Coworkers";
import History from "./components/History";
import News from "./components/News";

// tools
import Amplitude from 'amplitude-js'
import { setMetaTags } from "../../utils/metaTag";

const About = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState("transparent");
  const [backDropFilter, setBackDropFilter] = useState("blur(0px)");
  const [isWhite, setIsWhite] = useState(false);

  const [firstWayAnimation, setFirstWayAnimation] = useState(false);
  const [secondWayAnimation, setSecondWayAnimation] = useState(false);
  const [thirdWayAnimation, setThirdWayAnimation] = useState(false);
  const [fourthWayAnimation, setFourthWayAnimation] = useState(false);
  const [fifthWayAnimation, setFifthWayAnimation] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const [isNews, setIsNews] = useState(false);

  const clientHeight = document.documentElement.clientHeight;

  useEffect(() => {

    document.title = "회사소개 | 페이워크"; 

    setMetaTags({
        title: "회사소개 | 페이워크",
        description: "일하는 누구나 존중 받을 수 있는 새로운 근로 생태계를 꿈꿉니다. 견적서 관리 서비스 페이워크가 일하는 법을 만나보세요."
    })

    const handleScroll = () => {
      setScrollTop(document.documentElement.scrollTop);
      let scrollLocation = document.documentElement.scrollTop; // 현재 스크롤바 위치
      let windowHeight = window.innerHeight; // 스크린 창
      let fullHeight = document.body.scrollHeight; //  margin 값은 포함 x
      if (scrollLocation + windowHeight < fullHeight - 700) {
        setIsNews(false);
      }

      if (scrollLocation + windowHeight >= fullHeight - 700) {
        if (!isNews) {
          setIsNews(true);
        }
      }
    };

    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };

    // TODO: 팝업 사라지면 제거할 것
    document.body.style.overflow = "unset";

    Amplitude.getInstance().logEvent("22.02_회사 소개_PV")

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (scrollTop) {
      if (
        scrollTop >=
        clientHeight - (innerWidth > 744 ? 68 : innerWidth > 375 ? 58 : 48)
      ) {
        setBackgroundColor("rgba(255, 255, 255, 1)");
        setBackDropFilter("blur(0px)");
        setIsWhite(true);
      } else {
        setBackgroundColor("rgba(0, 0, 0, 0.4)");
        setBackDropFilter("blur(10px)");
        setIsWhite(false);
      }
    } else {
      setBackgroundColor("transparent");
      setBackDropFilter("blur(0px)");
    }
    if (scrollTop < 200) {
      setFirstWayAnimation(false);
      setSecondWayAnimation(false);
      setThirdWayAnimation(false);
      setFourthWayAnimation(false);
      setFifthWayAnimation(false);
    }
    let eventHeight = [];
    if (innerWidth > 744) {
      eventHeight = [200, 400, 600, 900, 1100];
    } else if (innerWidth <= 744 && innerWidth > 590) {
      eventHeight = [200, 400, 700, 1100, 1300];
    } else if (innerWidth <= 590 && innerWidth > 375) {
      eventHeight = [200, 400, 700, 1200, 1550];
    } else {
      eventHeight = [200, 400, 600, 1000, 1200];
    }
    scrollTop >= eventHeight[0] && setFirstWayAnimation(true);
    scrollTop >= eventHeight[1] && setSecondWayAnimation(true);
    scrollTop >= eventHeight[2] && setThirdWayAnimation(true);
    scrollTop >= eventHeight[3] && setFourthWayAnimation(true);
    scrollTop >= eventHeight[4] && setFifthWayAnimation(true);
  }, [scrollTop, clientHeight]);

  return (
    <Container>
      <Header
        backgroundColor={backgroundColor}
        backDropFilter={backDropFilter}
        isWhite={isWhite}
      />
      <Front innerWidth={innerWidth} />
      <WayWrapper>
        <Way
          wayAnimation={[
            firstWayAnimation,
            secondWayAnimation,
            thirdWayAnimation,
            fourthWayAnimation,
            fifthWayAnimation,
          ]}
          innerWidth={innerWidth}
        />
      </WayWrapper>
      <Wrapper>
        <Coworkers innerWidth={innerWidth} />
      </Wrapper>
      <Wrapper>
        <History innerWidth={innerWidth} />
      </Wrapper>
      <News isNews={isNews} innerWidth={innerWidth} />
    </Container>
  );
};

export default About;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const WayWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media ${(props) => props.theme.mobile} {
    display: block;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;

  @media ${(props) => props.theme.mobile} {
    /* display: block; */
    width: 375px;
    display: block;
  }
`;
