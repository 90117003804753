import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
    body {
        font-family: 'Noto Sans', sans-serif;
        margin: 0;
        padding: 0;
    }
    ul, li {
        list-style: none;
    }
    a {
        color: inherit;
        text-decoration: none;
    }
    html, body, #root {
        height: 100%;
        font-size: 62.5%; 
    }
    iframe {
        width: 320px !important;
        height: 680px !important;
ß
    }
`;
