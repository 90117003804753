import React, { useState, useEffect } from "react";
import styled from "styled-components";

//global
import colors from "../globals/colors";
import { Link } from "react-router-dom";

//images
import LogoWhite1440 from "../assets/images/logo_white_1440.png";
import LogoBlack1440 from "../assets/images/logo_black_1440.png";
import MenuBlack from "../assets/images/header/touch_menu_black.png";
import MenuWhite from "../assets/images/header/touch_menu_white.png";
import CloseBlack from "../assets/images/header/touch_close_black.png";
import CloseWhite from "../assets/images/header/touch_close_white.png";

// tools
import Amplitude from "amplitude-js";

const Header = (props) => {
  const { backgroundColor, backDropFilter, isWhite } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const MenuButton = isWhite || isMenuOpen ? MenuBlack : MenuWhite;
  const CloseButton = isWhite || isMenuOpen ? CloseBlack : CloseWhite;

  useEffect(() => {
    const handleClick = (e) => {
      if (!e.target.closest(".header")) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <Container
      backgroundColor={isMenuOpen ? "#fff" : backgroundColor}
      backDropFilter={backDropFilter}
      className="header"
    >
      <Inner>
        <InnerContainer>
          <Wrapper>
            <Link
              to="/"
              onClick={() =>
                Amplitude.getInstance().logEvent("22.02_홈화면_로고_클릭")
              }
            >
              <Logo
                src={isWhite || isMenuOpen ? LogoBlack1440 : LogoWhite1440}
              />
            </Link>
            {/* <Link 
              to="/service"
              onClick={()=>Amplitude.getInstance().logEvent("22.02_홈화면_서비스소개_클릭")}
            >
              <IntroduceButton color={isWhite ? colors.Body00 : "#fff"}>
                서비스 소개
              </IntroduceButton>
            </Link> */}
            <Link
              onClick={() =>
                window.open("https://console.paywork.io", "_blank")
              }
            >
              <IntroduceButton color={isWhite ? colors.Body00 : "#fff"}>
                견적서 작성하기
              </IntroduceButton>
            </Link>
            <Link
              to="/event"
              onClick={() =>
                Amplitude.getInstance().logEvent("22.02_홈화면_이벤트_클릭")
              }
            >
              <EventButton color={isWhite ? colors.Body00 : "#fff"}>
                이벤트
              </EventButton>
            </Link>
          </Wrapper>
          <Wrapper>
            {/* <CommunityButton
              color={isWhite ? colors.Body00 : "#fff"}
              onClick={() => {
                window.open("https://console.paywork.io", "_blank")
                Amplitude.getInstance().logEvent("22.03_홈화면_견적서 작성하기_클릭")
              }}
            >
              견적서 작성하기
            </CommunityButton> */}
            <LoginButton
              onClick={() => {
                window.open("https://console.paywork.io/signin/", "_blank");
                Amplitude.getInstance().logEvent("22.02_홈화면_로그인_클릭");
              }}
            >
              로그인
            </LoginButton>
            <Menu
              image={isMenuOpen ? CloseButton : MenuButton}
              onClick={() => {
                setIsMenuOpen(!isMenuOpen);

                if (isMenuOpen) {
                  Amplitude.getInstance().logEvent(
                    "22.02_홈화면_메뉴바_닫기_클릭"
                  );
                } else {
                  Amplitude.getInstance().logEvent("22.02_홈화면_메뉴바_클릭");
                }
              }}
            />
          </Wrapper>
        </InnerContainer>
        <MenuContainer isView={isMenuOpen}>
          <MenuList>
            <MenuContent>
              <AButton
                onClick={() => {
                  setIsMenuOpen(false);
                  window.open("https://console.paywork.io/signin/", "_blank");
                  Amplitude.getInstance().logEvent("22.02_홈화면_로그인_클릭");
                }}
              >
                견적서 작성하기
              </AButton>
            </MenuContent>
            {/* <MenuContent
              onClick={() => {
                setIsMenuOpen(false);
                Amplitude.getInstance().logEvent("22.02_홈화면_메뉴바_서비스 소개_클릭")
              }}
            >
              <ALink to="/service">서비스 소개</ALink>
            </MenuContent> */}
            <MenuContent
              onClick={() => {
                setIsMenuOpen(false);
                Amplitude.getInstance().logEvent(
                  "22.02_홈화면_메뉴바_이벤트_클릭"
                );
              }}
            >
              <ALink to="/event">이벤트</ALink>
            </MenuContent>
          </MenuList>
        </MenuContainer>
      </Inner>
    </Container>
  );
};

export default Header;

const Container = styled.nav`
  width: 100%;
  height: 68px;
  background-color: ${(props) => `${props.backgroundColor}`};
  backdrop-filter: ${(props) => `${props.backDropFilter}`};
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 9999;
  transition: 0.2s;

  @media ${(props) => props.theme.tablet} {
    height: 58px;
    position: fixed;
    /* width: 100%; */
  }
  @media ${(props) => props.theme.mobile} {
    display: block;
    position: sticky;
    width: 375px;

    height: 58px;
  }
`;

const Inner = styled.div`
  width: 1440px;
  @media ${(props) => props.theme.mobile} {
    max-width: 375px;
  }
`;

const InnerContainer = styled.div`
  margin: 0px 120px;
  height: 68px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media ${(props) => props.theme.tablet} {
    margin: 0px 38px 0px 50px;
    height: 58px;
  }
  @media ${(props) => props.theme.mobile} {
    margin: 0px 8px 0px 20px;
    height: 58px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Logo = styled.img`
  width: 120px;
  height: 30px;
  cursor: pointer;
`;

const IntroduceButton = styled.div`
  margin-left: 20px;
  padding: 6px 20px;
  font-size: 16px;
  font-weight: 400;
  color: ${(props) => `${props.color}`};
  cursor: pointer;

  @media ${(props) => props.theme.tablet} {
    display: none;
  }
`;

const EventButton = styled.div`
  padding: 6px 20px;
  font-size: 16px;
  font-weight: 400;
  color: ${(props) => `${props.color}`};
  cursor: pointer;
  @media ${(props) => props.theme.tablet} {
    display: none;
  }
`;

const CommunityButton = styled.div`
  padding: 6px 20px;
  font-size: 16px;
  font-weight: 400;
  color: ${(props) => `${props.color}`};
  cursor: pointer;
  @media ${(props) => props.theme.tablet} {
    display: none;
  }
`;

const LoginButton = styled.div`
  padding: 8px 20px;
  background-color: ${colors.B2};
  border-radius: 30px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
  @media ${(props) => props.theme.tablet} {
    padding: 6px 14px;
  }
`;

const Menu = styled.div`
  display: none;
  margin-left: 4px;
  width: 44px;
  height: 44px;
  background-image: ${(props) => `url(${props.image})`};
  background-size: cover;
  cursor: pointer;
  @media ${(props) => props.theme.tablet} {
    display: block;
  }
`;

const MenuContainer = styled.div`
  display: none;
  position: absolute;
  background-color: #fff;
  width: 100%;
  flex-direction: column;
  overflow-y: hidden;
  @media ${(props) => props.theme.tablet} {
    display: flex;
    height: ${(props) => (props.isView ? "102px" : "0px")};
    max-height: 100vh;
    transition: 0.2s ease;
  }
  @media ${(props) => props.theme.mobile} {
    display: flex;
    height: ${(props) => (props.isView ? "102px" : "0px")};
    max-height: 100vh;
    transition: 0.2s ease;
  }
`;

const MenuList = styled.ul`
  display: none;
  width: 100%;
  margin: 0px;
  padding: 0px;
  @media ${(props) => props.theme.tablet} {
    display: block;
  }
`;

const MenuContent = styled.li`
  width: 100%;
`;

const ALink = styled(Link)`
  display: block;
  padding: 18px 0px 18px 50px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: ${colors.Body00};
  cursor: pointer;

  transition: 0.2s;

  &:hover {
    background-color: ${colors.G8};
    transition: 0.2s;
  }

  @media ${(props) => props.theme.mobile} {
    padding: 18px 0px 18px 20px;
  }
`;

const AButton = styled.div`
  padding: 18px 0px 18px 50px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: ${colors.Body00};
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: ${colors.G8};
    transition: 0.2s;
  }

  @media ${(props) => props.theme.mobile} {
    padding: 18px 0px 18px 20px;
  }
`;
