import React, { useEffect } from "react";

//tool
import styled from "styled-components";

//global
import colors from "../../globals/colors";

//components
import Header from "../../components/Header";

//image
import Event0 from "./assets/img_event_0_580px.png";
import Event1 from "./assets/img_event_1_580px.png";
import Event2 from "./assets/img_event_2_580px.png";

//tools
import Amplitude from "amplitude-js";
import { setMetaTags } from "../../utils/metaTag";

const Event = () => {
  useEffect(() => {

    document.title = "선물 팡팡 이벤트 | 페이워크"; 

    setMetaTags({
        title: "선물 팡팡 이벤트 | 페이워크",
        description: "다양한 혜택이 기다리고 있어요. 견적서 생성 및 관리 서비스 페이워크에서 이벤트 참여하고 선물 받아가세요!"
    })
    // TODO: 팝업 사라지면 제거할 것
    document.body.style.overflow = "unset";
    Amplitude.getInstance().logEvent("22.02_이벤트_PV");
  }, []);

  return (
    <Container>
      <Header
        backgroundColor={"rgba(255, 255, 255, 1)"}
        backDropFilter={"blur(0px)"}
        isWhite={true}
      />
      <Inner>
        <Wrapper>
          <Title>페이워크 이벤트</Title>
          <InnerContainer>
            <RowEvent>
              <EachEvent
                className="event-with-ongoing"
                onClick={() => {
                  window.open(
                    "https://console.paywork.io/event/profiling",
                    "_blank"
                  );
                  Amplitude.getInstance().logEvent(
                    "22.02_이벤트_프로파일링_클릭"
                  );
                }}
              >
                <ImageBox>
                  <EventImage
                    className="hover-transform"
                    src={Event1}
                    alt="진행중 이벤트"
                  />
                </ImageBox>
                <EventTitle>
                  추가 정보 입력하고 스타벅스 아메리카노 받아가세요
                </EventTitle>
                <EventInfo>
                  <EventStatus>{`[진행중]`}</EventStatus>
                  <EventDueDate>2022-02-07 ~ 상시</EventDueDate>
                </EventInfo>
              </EachEvent>

              <EachEvent
                className="event-ongoing"
              >
                <ImageBox>
                  <EventImage
                    src={Event2}
                    alt="종료된 이벤트"
                  />
                </ImageBox>
                <EventTitle>
                  매일 견적서 생성하고 룰렛 이벤트에 도전하세요
                </EventTitle>
                <EventInfo>
                  <EventStatus className="black">{`[종료]`}</EventStatus>
                  <EventDueDate>2022-02-07 ~ 2022-04-22</EventDueDate>
                </EventInfo>
              </EachEvent>
              
              <EachEvent className="event-end">
                <ImageBox>
                  <EventImage src={Event0} alt="종료 이벤트" />
                </ImageBox>
                <EventTitle>
                  페이워크에서 놀고 WeWork 공유오피스 무료로 쓰자
                </EventTitle>
                <EventInfo>
                  <EventStatus className="black">{`[종료]`}</EventStatus>
                  <EventDueDate>2021-12-31 - 2022-02-02</EventDueDate>
                </EventInfo>
              </EachEvent>
            </RowEvent>
            {/* <RowEvent>
              <EachEvent>
                <ImageBox>
                  <EventImage src={Event0} alt="종료 이벤트" />
                </ImageBox>
                <EventTitle>
                  페이워크에서 놀고 WeWork 공유오피스 무료로 쓰자
                </EventTitle>
                <EventInfo>
                  <EventStatus className="black">{`[종료]`}</EventStatus>
                  <EventDueDate>2022-02-02</EventDueDate>
                </EventInfo>
              </EachEvent>
              <EachEvent className="event-end">
                <ImageBox>
                  <EventImage src={Event0} alt="종료 이벤트" />
                </ImageBox>
                <EventTitle>
                  페이워크에서 놀고 WeWork 공유오피스 무료로 쓰자
                </EventTitle>
                <EventInfo>
                  <EventStatus className="black">{`[종료]`}</EventStatus>
                  <EventDueDate>2022-02-02</EventDueDate>
                </EventInfo>
              </EachEvent>
            </RowEvent> */}
          </InnerContainer>
        </Wrapper>
      </Inner>
    </Container>
  );
};

export default Event;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 360px);

  @media ${(props) => props.theme.mobile} {
    margin-top: -58px;
  }
`;

const Inner = styled.div`
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media ${(props) => props.theme.mobile} {
    display: block;
  }
`;

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 218px 120px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${(props) => props.theme.tablet} {
    margin: 158px 50px 0px;
    /* 좌측정렬 */
    /* display: block; */
  }
  @media ${(props) => props.theme.mobile} {
    min-width: 335px;
    margin: 138px 20px 0px;
    /* 좌측정렬 */
    /* display: flex; */
  }
`;

const Title = styled.div`
  font-size: 42px;
  font-weight: 600;
  color: ${colors.Body00};
  @media ${(props) => props.theme.tablet} {
    font-size: 34px;
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 24px;
  }
`;

const InnerContainer = styled.div`
  /* width: 100%; */
  margin-top: 100px;

  @media ${(props) => props.theme.tablet} {
    margin-top: 60px;
  }
  @media ${(props) => props.theme.mobile} {
    margin-top: 40px;
  }
`;

const RowEvent = styled.div`
  /* width: 100%; */
  margin-bottom: 60px;
  display: flex;
  flex-direction: row;

  .event-end {
    margin-left: 40px;
  }

  .event-with-ongoing {
    cursor: pointer;
    margin-right: 40px;
  }

  @media ${(props) => props.theme.tablet} {
    flex-direction: column;
    margin-bottom: 40px;
    .event-end {
      margin-top: 40px;
      margin-left: 0px;
    }

    .event-with-ongoing {
      margin-right: 0px;
      margin-bottom: 40px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    margin-bottom: 60px;
    .event-end {
      margin-top: 60px;
      margin-left: 0px;
    }
  }
`;

const EachEvent = styled.div`
  .hover-transform {
    transform: scale(1);
    transition: transform 0.55s 0.2s ease-out, opacity 0.55s 0.2s linear;
  }
  &:hover {
    .hover-transform {
      transform: scale(1.05);
      transition: transform 0.55s 0.2s ease-out, opacity 0.55s 0.2s linear;
    }
  }
`;

const ImageBox = styled.div`
  overflow: hidden;
  border-radius: 30px;
`;

const EventImage = styled.img`
  display: block;
  width: 100%;
  @media ${(props) => props.theme.mobile} {
    max-width: 335px;
  }
`;

const EventTitle = styled.div`
  margin-top: 20px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: ${colors.Body00};
  height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  @media ${(props) => props.theme.mobile} {
    font-size: 16px;
    line-height: 24px;
    height: 24px;
  }
`;

const EventInfo = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  .black {
    color: ${colors.Body00};
  }

  @media ${(props) => props.theme.mobile} {
    margin-top: 12px;
  }
`;

const EventStatus = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${colors.B2};

  @media ${(props) => props.theme.mobile} {
    font-size: 12px;
  }
`;

const EventDueDate = styled.div`
  margin-left: 6px;
  font-size: 16px;
  font-weight: 400;
  color: ${colors.G3};

  @media ${(props) => props.theme.mobile} {
    font-size: 12px;
    margin-left: 4px;
  }
`;
