import React, { useEffect, useState } from "react";

// globals
import colors from "../../../globals/colors";

// images
import FirstSectionImage1 from "../assets/section1_image1.png";
import FirstSectionImage2 from "../assets/section1_image2.png";
import ScrollSectionImage1 from "../assets/section2_image1.png";
import ScrollSectionImage2 from "../assets/section2_image2.png";
import ScrollSectionImage3 from "../assets/section2_image3.png";
import ScrollSectionImage4 from "../assets/section2_image4.png";
import LastSectionImage1 from "../assets/section3_image1.png";
import LastSectionImage2 from "../assets/section3_image2.png";
import ServiceInfoImage from "../assets/service_info_bg.png";

// tools
import styled from "styled-components";
import Amplitude from "amplitude-js";

const Sub = () => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const onClickButton = () => {
    window.open("https://console.paywork.io/signin", "_blank");
    Amplitude.getInstance().logEvent(
      "22.02_서비스소개_지금바로견적서쓰러가기_클릭"
    );
  };
  useEffect(() => {
    window.addEventListener("resize", onChangeInnerWidth);

    return () => {
      window.removeEventListener("resize", onChangeInnerWidth);
    };
  }, []);

  const onChangeInnerWidth = () => {
    setInnerWidth(window.innerWidth);
  };
  return (
    <Container>
      <TextContent>
        {/* <b>
          쓰세요, 견적서
          <br />
          페이워크에서
        </b>
        <span>
          상대방이 견적서를 승낙하면 계약서와 같아요
          <br />내 권리는 내가 먼저 챙겨요
        </span> */}
        <b>
          쓰세요, 견적서
          <br />
          페이워크에서
        </b>
      </TextContent>
      <SectionContainer>
        <Section>
          <SectionTextContent innerWidth={innerWidth}>
            <b>
              <span>채워요,</span> 빈칸만
            </b>
            <p>
              <span>
                견적서 탬플릿 찾는데&nbsp;
                <span className="enter-span">
                  <br />
                </span>
                더 이상 시간 쓰지 마세요
              </span>
              <br />
              작성할 내용은&nbsp;
              <span className="enter-span">
                <br />
              </span>
              페이워크가 준비해뒀어요
            </p>
          </SectionTextContent>
          <SectionImageContent>
            <img src={FirstSectionImage1} alt="" />
            <img src={FirstSectionImage2} alt="" />

            <div className="image-content">
              언제 어디서나 간편하게 작성 완료!
            </div>
          </SectionImageContent>
        </Section>

        <Section>
          <SectionTextContent
            className="text-end-content sticky-text"
            innerWidth={innerWidth}
          >
            <b>
              <span>편해요,</span>공유도
            </b>
            <p>
              이미지, PDF 파일, 링크, 알림톡
              <br />
              편한 방법으로 견적서를
              <span className="enter-span">
                <br />
              </span>
              &nbsp;바로 공유할 수 있어요
            </p>
          </SectionTextContent>
          <ScrollImageContent>
            <img src={ScrollSectionImage1} alt="" />
            <img src={ScrollSectionImage2} alt="" />
            <img src={ScrollSectionImage3} alt="" />
            <img src={ScrollSectionImage4} alt="" />
          </ScrollImageContent>
        </Section>

        <Section>
          <SectionTextContent
            className="text-center-content"
            innerWidth={innerWidth}
          >
            <b>
              <span>괜찮아요,</span> 일만해도
            </b>
            <p>
              <span>
                업무 히스토리는 물론,&nbsp;
                <span className="enter-span">
                  <br />
                </span>
                정산이 필요한 날도
              </span>
              <br />
              페이워크가 기억하고 있을게요
            </p>
          </SectionTextContent>
          <SectionImageContent>
            <img src={LastSectionImage1} alt="" />
            <img src={LastSectionImage2} alt="" />
          </SectionImageContent>
        </Section>
      </SectionContainer>

      <InfoContainer>
        <p>
          <span>
            이제,
            <br />
            페이워크와 함께
            <br />
          </span>
          일에만 집중하세요
        </p>
        <button onClick={onClickButton}>지금 바로 견적서 쓰러 가기</button>
      </InfoContainer>
    </Container>
  );
};
export default Sub;

const Container = styled.div`
  position: sticky;
  background-color: #fff;
  z-index: 990;
`;
const TextContent = styled.div`
  padding: 150px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${colors.Body00};
  text-align: center;

  b {
    margin-bottom: 30px;
    font-size: 74px;
    line-height: 100px;
  }
  span {
    font-size: 24px;
    line-height: 34px;
  }

  @media ${(props) => props.theme.tablet} {
    padding: 100px 0 0;

    b {
      margin-bottom: 20px;
      font-size: 54px;
      line-height: 80px;
    }
    span {
      font-size: 20px;
      line-height: 30px;
    }
  }
  @media ${(props) => props.theme.mobile} {
    padding: 80px 0 0;

    b {
      margin-bottom: 20px;
      font-size: 40px;
      line-height: 50px;
    }
    span {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;
const SectionContainer = styled.div`
  padding: 0 120px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1200px;

  @media ${(props) => props.theme.tablet} {
    padding: 0 50px;
  }
  @media ${(props) => props.theme.mobile} {
    padding: 0 20px;
  }
`;
const Section = styled.div`
  padding-top: 260px;
  width: 100%;

  @media ${(props) => props.theme.tablet} {
    padding-top: 180px;
  }
  @media ${(props) => props.theme.tablet} {
    padding-top: 120px;
  }
`;
const SectionTextContent = styled.div`
  b {
    display: flex;
    align-items: center;
    min-height: 82px;
    color: ${colors.Body00};
    font-size: ${(props) => (props.innerWidth > 1000 ? "34px" : "3vw")};

    span {
      margin-right: 10px;
      padding: 16px;
      color: ${colors.Primary00};
      background-color: #ebf3ff;
      border-radius: 40px;
    }
  }

  p {
    margin: 20px 0 0;
    color: ${colors.G2};

    font-size: ${(props) => (props.innerWidth > 1000 ? "24px" : "2vw")};
    line-height: 1.5;

    span {
      padding: 0;
      color: ${colors.Body00};
    }
  }
  .enter-span {
    display: none;
  }
  &.text-end-content {
    b {
      justify-content: flex-end;
    }
    p {
      text-align: right;
    }
  }
  &.sticky-text {
    position: sticky;
    top: 218px;
    right: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .enter-span {
      display: none;
    }
  }
  &.text-center-content {
    display: flex;
    align-items: center;
    flex-direction: column;

    p {
      text-align: center;
    }
  }

  @media (max-width: 1300px) {
    &.sticky-text {
      .enter-span {
        display: block;
        width: 0;
        height: 0;
      }
    }
  }

  @media ${(props) => props.theme.tablet} {
    b {
      min-height: 70px;
      font-size: 34px;
    }
    span {
      margin-right: 8px;
      padding: 18px;
    }

    p {
      margin: 20px 0 0;
      color: ${colors.G2};

      font-size: 20px;
      line-height: 30px;

      span {
        color: ${colors.Body00};
      }
    }

    &.sticky-text {
      position: static;

      display: flex;
      justify-content: flex-start;

      .enter-span {
        display: none;
      }
    }
  }
  @media (max-width: 500px) {
    .enter-span {
      display: block;
      width: 0;
      height: 0;
    }
    &.sticky-text {
      .enter-span {
        display: block;
        width: 0;
        height: 0;
      }
    }
  }
  @media ${(props) => props.theme.mobile} {
    b {
      min-height: 52px;
      font-size: 24px;
    }
    span {
      margin-right: 6px;
      padding: 14px;
    }

    p {
      margin: 14px 0 0;
      color: ${colors.G2};

      font-size: 16px;
      line-height: 24px;

      span {
        color: ${colors.Body00};
      }
    }
    .enter-span {
      display: none;
    }
    &.sticky-text {
      position: static;

      display: flex;
      justify-content: flex-start;

      .enter-span {
        display: none;
      }
    }
  }
  @media (max-width: 360px) {
    &.sticky-text {
      .enter-span {
        display: block;
        width: 0;
        height: 0;
      }
    }
  }
`;
const SectionImageContent = styled.div`
  margin: 80px 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;

  img {
    width: 48%;
  }

  .image-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    width: 100%;
    height: 146px;
    color: #fff;
    font-size: 24px;
    background-color: ${colors.mainG};
    background-image: url(${ServiceInfoImage});
    background-position: right center;
    background-size: auto 146px;
    background-repeat: no-repeat;
    border-radius: 40px;
  }

  @media ${(props) => props.theme.tablet} {
    flex-direction: column;

    img {
      margin-bottom: 40px;
      width: 100%;
    }

    .image-content {
      margin: 0;
      font-size: 20px;
      background-position: right center;
    }
  }
  @media ${(props) => props.theme.mobile} {
    margin: 60px 0 0;

    .image-content {
      margin: 0;
      height: 73px;
      font-size: 20px;
      background-position: right center;
      border-radius: 15px;
    }
  }
`;
const ScrollImageContent = styled.div`
  margin-top: -170px;
  width: 50%;

  img {
    margin-bottom: 40px;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media ${(props) => props.theme.tablet} {
    margin-top: 80px;
    width: 100%;
  }
  @media ${(props) => props.theme.tablet} {
    margin-top: 60px;
    width: 100%;
  }
`;
const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 150px;
  width: 100%;
  height: 558px;
  background-color: #28272f;
  box-sizing: border-box;

  p {
    margin: 0 0 67px;
    color: #fff;
    font-size: 42px;
    line-height: 55px;
    font-weight: bold;
    text-align: center;

    span {
      color: ${colors.G5};
    }
  }

  button {
    width: 418px;
    height: 76px;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    border-radius: 40px;
    background-color: ${colors.B2};
    border: 0;
    cursor: pointer;
  }

  @media ${(props) => props.theme.tablet} {
    margin-top: 40px;
    height: 467px;

    p {
      margin: 0 0 50px;
      font-size: 34px;
      line-height: 47px;
    }
  }
  @media (max-width: 500px) {
    button {
      width: 85%;
    }
  }
  @media ${(props) => props.theme.mobile} {
    margin-top: 20px;
    height: 315px;

    p {
      margin: 0 0 50px;
      font-size: 24px;
      line-height: 33px;
    }
    button {
      width: calc(100%-40px);
      height: 66px;
      font-size: 16px;
    }
  }
`;
