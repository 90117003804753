import React, { useEffect, useState } from "react";

// images
// import PopupImage from "../assets/images/popup/profile_popup.png";
import MobilePopupImage from "../assets/images/popup/mobile_profile_popup.png";
import PopupButtonImage from "..//assets/images/popup/btn_web_popup.png";
import CheckboxImage from "../assets/images/popup/checkbox.png";
import CheckboxOnImage from "../assets/images/popup/checkbox_on.png";
import CloseImage from "../assets/images/popup/close.png";
import MobileCloseImage from "../assets/images/popup/ic_fill_close_circle_g_20.png";

import PopupImage from "../assets/images/popup/popup_pc.png";
import ButtonImage from "../assets/images/popup/btn_pc.png";

// tool
import styled from "styled-components";
import Amplitude from "amplitude-js";
import { isMobileOnly, isIOS } from "react-device-detect";

const Popup = () => {
  const HAS_VISITED_BEFORE = localStorage.getItem("hasVisitedBefore");
  const [showModal, setShowModal] = useState(HAS_VISITED_BEFORE);
  const [isVisit, setIsVisit] = useState(false);
  const [isSelect, setIsSelect] = useState(false);

  useEffect(() => {
    const handleShowModal = () => {
      if (HAS_VISITED_BEFORE && HAS_VISITED_BEFORE > new Date()) {
        setShowModal(false);
        document.body.style.overflow = "unset";
      }

      if (!HAS_VISITED_BEFORE || HAS_VISITED_BEFORE <= new Date()) {
        if (isMobileOnly) {
          Amplitude.getInstance().logEvent("22.03_M_룰렛_이벤트팝업_PV");
        } else {
          Amplitude.getInstance().logEvent("22.03_PC_룰렛_이벤트팝업_PV");
        }

        setShowModal(true);
        document.body.style.overflow = "hidden";
      }
    };

    handleShowModal();
  }, [HAS_VISITED_BEFORE]);

  const handleClose = () => {
    if (isMobileOnly) {
      Amplitude.getInstance().logEvent("22.03_M_룰렛_이벤트팝업_닫기_클릭");
    } else {
      Amplitude.getInstance().logEvent("22.03_PC_룰렛_이벤트팝업_닫기_클릭");
    }

    setShowModal(false);
    document.body.style.overflow = "unset";

    if (isSelect) {
      setIsVisit(true);
    } else {
      setIsVisit(false);
    }
  };
  const handleIsVisit = () => {
    // Amplitude.getInstance().logEvent("22.02_프로파일링_팝업_하루보지않기_클릭");
    setIsVisit(!isVisit);
  };
  useEffect(() => {
    if (isVisit) {
      setShowModal(false);
      let expires = new Date();
      expires = expires.setHours(expires.getHours() + 24);
      localStorage.setItem("hasVisitedBefore", expires);
    }
  }, [isVisit]);

  const onChangeSelectBox = () => {
    if (isMobileOnly) {
      Amplitude.getInstance().logEvent(
        "22.03_M_룰렛_이벤트팝업_오늘하루동안안보기_클릭"
      );
    } else {
      Amplitude.getInstance().logEvent(
        "22.03_PC_룰렛_이벤트팝업_오늘하루동안안보기_클릭"
      );
    }

    setIsSelect(!isSelect);
  };

  return (
    <>
      {showModal && (
        <ModalBackgroundContainer>
          <Container>
            <RouletteButton
              onClick={() => {
                if (isMobileOnly) {
                  Amplitude.getInstance().logEvent(
                    "22.03_M_룰렛_이벤트팝업_룰렛돌리러가기_클릭"
                  );
                } else {
                  Amplitude.getInstance().logEvent(
                    "22.03_PC_룰렛_이벤트팝업_룰렛돌리러가기_클릭"
                  );
                }

                window.open(
                  "https://console.paywork.io/event/roulette",
                  "_blank"
                );
              }}
            />
            <img className="popup-image" src={PopupImage} alt="" />
            <img className="mobile-popup-image" src={PopupImage} alt="" />
            <CloseWrapper>
              <TodayWrapper>
                <CheckBoxToday
                  src={isSelect ? CheckboxOnImage : CheckboxImage}
                  onClick={onChangeSelectBox}
                />
                <TextToday onClick={onChangeSelectBox}>
                  오늘 하루동안 안보기
                </TextToday>
              </TodayWrapper>
              <CloseButton src={CloseImage} onClick={handleClose} />
            </CloseWrapper>
          </Container>
        </ModalBackgroundContainer>
      )}
    </>
  );
};

export default Popup;
const ModalBackgroundContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;

  overflow-y: hidden;
  button {
    border: 0;
    background-color: rgba(0, 0, 0, 0);
  }
`;
const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  height: 550px;
  z-index: 1000;
  .popup-image {
    width: 550px;
  }

  .mobile-popup-image {
    display: none;
  }

  @media ${(props) => props.theme.tablet} {
    width: 400px;
    height: 400px;

    .popup-image {
      width: 400px;
    }
  }
`;

const RouletteButton = styled.div`
  width: 251px;
  height: 65px;
  background-size: 100% 100%;
  background-image: url(${ButtonImage});
  background-position: 0 0, center;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0px;
  cursor: pointer;
  position: absolute;
  left: 0;
  margin-left: 149px;
  top: 0;
  margin-top: 170px;

  @media ${(props) => props.theme.tablet} {
    width: 186px;
    height: 45px;
    margin-left: 105px;
    margin-top: 125px;
  }
`;

const CloseWrapper = styled.div`
  position: absolute;
  width: 510px;
  height: 40px;
  background-color: #000000;
  padding: 0 20px;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${(props) => props.theme.tablet} {
    bottom: 0;
    width: 380px;
    height: 25px;
    padding: 0 10px;
  }
`;

const CloseButton = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;

  @media ${(props) => props.theme.tablet} {
    width: 16px;
    height: 16px;
  }
`;

const TodayWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CheckBoxToday = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;

  @media ${(props) => props.theme.tablet} {
    width: 16px;
    height: 16px;
  }
`;

const TextToday = styled.div`
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  margin-left: 4px;
  cursor: pointer;

  @media ${(props) => props.theme.tablet} {
    font-size: 10px;
  }
`;
