import React, { useEffect } from 'react'

// components
import Header from '../../components/Header'
import Main from './components/Main'
import Sub from './components/Sub'

// tools
import Amplitude from 'amplitude-js'
import { setMetaTags } from '../../utils/metaTag'
import Popup from '../../components/Popup'

const Service = () => {
    useEffect(()=>{

        setMetaTags({
            title: "견적서 페이워크",
            description: "계약을 따내는 빠른 방법, 이제 모바일로 견적서를 손쉽게 만들어 고객에게 전달하세요! PDF내보내기, 카톡 공유, 정산 알림요청까지 해드립니다"
        })

        // 팝업 사라지면 제거할 것
        document.body.style.overflow = "unset"
        Amplitude.getInstance().logEvent("22.02_서비스소개_PV")
    }, [])
    return (
        <div style={{minHeight: '600vh'}}>
            <Header
                backgroundColor='rgba(255, 255, 255, 0.4)'
                backDropFilter='blur(10px)'
                isWhite={true}
            />
            {/* <Popup /> */}
            <Main />
            <Sub />
        </div>
    )
}

export default Service