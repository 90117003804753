import React, { useState, useEffect } from "react";

// tools
import styled, { keyframes, css } from "styled-components";

// globals
import colors from "../../../globals/colors";

// images
import PrevOffImg from "../../../assets/images/about/ic_left_off_80px.png";
import PrevOnImg from "../../../assets/images/about/ic_left_on_80px.png";
import NextOffImg from "../../../assets/images/about/ic_right_off_80px.png";
import NextOnImg from "../../../assets/images/about/ic_right_on_80px.png";

const NEWS1 = [
  {
    name: "플래텀",
    date: "2022-01-17",
    title: "스파크랩 18기 액셀러레이팅 프로그램 참여하는 14개 스타트업",
    url: "https://platum.kr/archives/179097",
    no: 1,
  },
  {
    name: "매일경제",
    date: "2021-11-11",
    title: "스파크랩, '2021 뉴 콘텐츠랩' 온라인 데모데이 종료",
    url: "https://www.mk.co.kr/news/business/view/2021/11/1066649/",
    no: 2,
  },
  {
    name: "매거진 한경",
    date: "2021-10-13",
    title: "프리랜서·소상공인 이제는 일에만 집중할 수 있다",
    url: "https://magazine.hankyung.com/job-joy/article/202110133577d",
    no: 3,
  },
  {
    name: "데일리경제",
    date: "2021-08-06",
    title: "페이워크(주), 중기부 스마트 서비스 지원사업 선정",
    url: "http://www.kdpress.co.kr/news/articleView.html?idxno=106674",
    no: 4,
  },
  {
    name: "벤처스퀘어",
    date: "2021-06-28",
    title: "프리랜서의 계약 '이후'를 도와주는 곳, 페이워크",
    url: "https://www.venturesquare.net/831704",
    no: 5,
  },
];

const News = ({ newsRef, isNews, innerWidth }) => {
  const [start, setStart] = useState(0);
  const [newsData, setNewsData] = useState(NEWS1.slice(0, 4));
  const titleFontSize =
    innerWidth > 1300
      ? 42
      : innerWidth > 744
      ? 42 - 8 * ((1300 - innerWidth) / (1300 - 744))
      : 34 - 10 * ((744 - innerWidth) / (744 - 375));
  const arrowSize =
    innerWidth > 1300
      ? 80
      : innerWidth >= 744
      ? 80 - 30 * ((1300 - innerWidth) / (1300 - 744))
      : 50 - 10 * ((743 - innerWidth) / (743 - 375));
  const arrowHeightSize =
    innerWidth >= 744 ? 80 : 80 - 20 * ((743 - innerWidth) / (743 - 375));
  const mobileNewsHeight =
    innerWidth >= 744 ? 96 : 96 - 24 * ((743 - innerWidth) / (743 - 375));
  const mobileNewsSideMargin =
    innerWidth >= 744 ? 40 : 40 - 20 * ((743 - innerWidth) / (743 - 375));
  const mobileTitleNewsFontSize =
    innerWidth >= 744 ? 20 : 20 - 4 * ((743 - innerWidth) / (743 - 375));
  const mobileTitleNewsLineHeight =
    innerWidth >= 744 ? 32 : 32 - 8 * ((743 - innerWidth) / (743 - 375));
  const sideMargin =
    innerWidth > 1300 ? 60 : 60 - 30 * ((1300 - innerWidth) / (1300 - 744));
  const contentsMarginTop =
    innerWidth > 1300
      ? 100
      : innerWidth > 744
      ? 100 - 60 * ((1300 - innerWidth) / (1300 - 744))
      : 60 - 20 * ((744 - innerWidth) / (744 - 375));
  const newsWidth =
    innerWidth > 1300 ? 270 : 270 - 7 * ((1300 - innerWidth) / (1300 - 744));
  const newsWidthTM =
    innerWidth > 744 ? 263 : 263 - 105 * ((744 - innerWidth) / (744 - 375));
  const newsHeightTM =
    innerWidth > 744 ? 96 : 96 - 24 * ((744 - innerWidth) / (744 - 375));
  const containerMarginTopTM =
    innerWidth > 744 ? 180 : 180 - 100 * ((744 - innerWidth) / (744 - 375));

  useEffect(() => {
    if (innerWidth > 1500) {
      let sliceNews = [];
      if (start === 2) {
        sliceNews = NEWS1.slice(start - 1, start + 3);
      } else if (start === 3) {
        sliceNews = NEWS1.slice(start - 2, start + 2);
      } else {
        sliceNews = NEWS1.slice(start, start + 4);
      }

      return setNewsData(sliceNews);
    } else if (innerWidth <= 1500 && innerWidth > 1200) {
      let sliceNews = [];

      if (start === 3) {
        sliceNews = NEWS1.slice(start - 1, start + 2);
      } else {
        sliceNews = NEWS1.slice(start, start + 3);
      }

      return setNewsData(sliceNews);
    } else if (innerWidth <= 1200) {
      const sliceNews = NEWS1.slice(start, start + 2);

      return setNewsData(sliceNews);
    }
  }, [innerWidth]);

  const onChangeNext = () => {
    if (innerWidth > 1500) {
      const sliceNews = NEWS1.slice(start + 1, start + 5);

      setStart(start + 1);
      setNewsData(sliceNews);
    }

    if (innerWidth <= 1500 && innerWidth > 1200) {
      const sliceNews = NEWS1.slice(start + 1, start + 4);

      setStart(start + 1);
      setNewsData(sliceNews);
    }

    if (innerWidth <= 1200) {
      const sliceNews = NEWS1.slice(start + 1, start + 3);

      setStart(start + 1);
      setNewsData(sliceNews);
    }
  };

  const onChangePrev = () => {
    if (innerWidth > 1500) {
      const sliceNews = NEWS1.slice(start - 1, start + 3);

      setStart(start - 1);
      setNewsData(sliceNews);
    }

    if (innerWidth <= 1500 && innerWidth > 1200) {
      const sliceNews = NEWS1.slice(start - 1, start + 2);

      setStart(start - 1);
      setNewsData(sliceNews);
    }

    if (innerWidth < 1200) {
      const sliceNews = NEWS1.slice(start - 1, start + 1);

      setStart(start - 1);
      setNewsData(sliceNews);
    }
  };

  return (
    <Container ref={newsRef} containerMarginTopTM={containerMarginTopTM}>
      <Wrapper isNews={isNews}>
        <Header titleFontSize={titleFontSize} isNews={isNews}>
          언론에서 바라보는 페이워크
        </Header>
        <Content
          isNews={isNews}
          contentsMarginTop={contentsMarginTop}
          isMobile={innerWidth < 744}
        >
          <PrevArrow
            src={newsData[0].no === 1 ? PrevOffImg : PrevOnImg}
            onClick={() => {
              if (newsData[0].no !== 1) {
                onChangePrev();
              }
            }}
            isFirst={newsData[0].no === 1}
            arrowSize={arrowSize}
            sideMargin={sideMargin}
            isMobile={innerWidth < 744}
          />
          <NewsList start={start} isMobile={innerWidth < 744}>
            {newsData.map((news, idx) => {
              return (
                <NewsWrapper
                  key={idx}
                  lastCheck={
                    (innerWidth > 1500 && idx === 3) ||
                    (innerWidth <= 1500 && innerWidth > 1200 && idx === 2) ||
                    (innerWidth <= 1200 && idx === 1)
                  }
                  mobileNewsSideMargin={mobileNewsSideMargin}
                  onClick={() => window.open(news.url, "_blank")}
                >
                  <BarNews />
                  <TitleNews
                    mobileTitleNewsFontSize={mobileTitleNewsFontSize}
                    mobileTitleNewsLineHeight={mobileTitleNewsLineHeight}
                    mobileNewsHeight={mobileNewsHeight}
                    newsWidth={newsWidth}
                    newsWidthTM={newsWidthTM}
                    newsHeightTM={newsHeightTM}
                  >
                    {news.title}
                  </TitleNews>
                  <InfoNews>
                    <NameInfo>{news.name}</NameInfo>
                    <DateInfo>{news.date}</DateInfo>
                  </InfoNews>
                </NewsWrapper>
              );
            })}
          </NewsList>
          <NextArrow
            src={
              newsData[newsData.length - 1].no === 5 ? NextOffImg : NextOnImg
            }
            onClick={() => {
              if (newsData[newsData.length - 1].no !== 5) {
                onChangeNext();
              }
            }}
            isLast={newsData[newsData.length - 1].no === 5}
            arrowSize={arrowSize}
            sideMargin={sideMargin}
            isMobile={innerWidth < 744}
          />
          <MobileArrowWrapper isMobile={innerWidth < 744}>
            <MobilePrevArrow
              isMobile={innerWidth < 744}
              isFirst={newsData[0].no === 1}
              src={newsData[0].no === 1 ? PrevOffImg : PrevOnImg}
              arrowSize={arrowSize}
              arrowHeightSize={arrowHeightSize}
              onClick={() => {
                if (newsData[0].no !== 1) {
                  onChangePrev();
                }
              }}
            />
            <MobileNextArrow
              isMobile={innerWidth < 744}
              isLast={newsData[newsData.length - 1].no === 5}
              src={
                newsData[newsData.length - 1].no === 5 ? NextOffImg : NextOnImg
              }
              arrowSize={arrowSize}
              arrowHeightSize={arrowHeightSize}
              onClick={() => {
                if (newsData[newsData.length - 1].no !== 5) {
                  onChangeNext();
                }
              }}
            />
          </MobileArrowWrapper>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default News;

const Container = styled.div`
  margin-top: 150px;
  min-height: 492px;
  background: ${colors.Body00};
  width: 100%;
  display: flex;
  justify-content: center;

  @media ${(props) => props.theme.tablet} {
    margin-top: ${(props) => `${props.containerMarginTopTM}px`};
  }

  @media ${(props) => props.theme.mobile} {
    margin-top: 80px;
    width: 375px;
    display: block;
  }
`;

const Wrapper = styled.div`
  margin: 100px 10px 150px;
  width: 1480px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1480px) {
    width: 100%;
  }

  @media ${(props) => props.theme.mobile} {
    margin: 60px 0px 120px;
    width: auto;
  }
`;

const FadeIn = () => keyframes`
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
    transition: all ease-out 2s;
  }
`;

const Header = styled.div`
  display: ${(props) => (props.isNews ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: ${(props) => `${props.titleFontSize}px`};
  font-weight: 600;

  ${(props) =>
    props.isNews &&
    css`
      animation: ${FadeIn} 2s;
    `};

  @media ${(props) => props.theme.mobile} {
    font-size: 24px;
  }
`;

const Content = styled.div`
  display: ${(props) => (props.isNews ? "flex" : "none")};
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  margin-top: ${(props) => `${props.contentsMarginTop}px`};
  justify-content: center;

  ${(props) =>
    props.isNews &&
    css`
      animation: ${FadeIn} 2s;
    `};

  @media ${(props) => props.theme.tablet} {
    margin-top: 40px;
  }
`;

const PrevArrow = styled.img`
  width: ${(props) => `${props.arrowSize}px`};
  height: 80px;
  margin-right: ${(props) => `${props.sideMargin}px`};
  margin-top: 49px;
  cursor: ${(props) => (props.isFirst ? "unset" : "pointer")};
  display: ${(props) => props.isMobile && "none"};

  @media ${(props) => props.theme.tablet} {
    margin-right: 10px;
  }
`;

const NextArrow = styled.img`
  width: ${(props) => `${props.arrowSize}px`};
  height: 80px;
  margin-left: ${(props) => `${props.sideMargin}px`};
  margin-top: 49px;
  cursor: ${(props) => (props.isLast ? "unset" : "pointer")};
  display: ${(props) => props.isMobile && "none"};

  @media ${(props) => props.theme.tablet} {
    margin-left: 10px;
  }
`;

const MobileArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const MobilePrevArrow = styled.img`
  /* width: ${(props) => `${props.arrowSize}px`};
  height: ${(props) => `${props.arrowHeightSize}px`}; */
  width: 40px;
  height: 60px;
  margin-top: 40px;
  cursor: ${(props) => (props.isFirst ? "unset" : "pointer")};
  display: ${(props) => (props.isMobile ? "block" : "none")};
`;

const MobileNextArrow = styled.img`
  /* width: ${(props) => `${props.arrowSize}px`};
  height: ${(props) => `${props.arrowHeightSize}px`}; */
  width: 40px;
  height: 60px;
  margin-top: 40px;
  cursor: ${(props) => (props.isLast ? "unset" : "pointer")};
  display: ${(props) => (props.isMobile ? "block" : "none")};
`;

const NewsList = styled.div`
  display: flex;
  max-width: 1480px;
  justify-content: ${(props) => props.isMobile && "center"};

  @media ${(props) => props.theme.tablet} {
    margin: 0 20px;
  }
`;

const NewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${(props) => (props.lastCheck ? "0px" : "40px")};
  cursor: pointer;

  @media ${(props) => props.theme.tablet} {
    margin-right: ${(props) =>
      props.lastCheck ? "0px" : `${props.mobileNewsSideMargin}px`};
  }

  @media ${(props) => props.theme.mobile} {
    /* margin-right: ${(props) => (props.lastCheck ? "0px" : `20px`)}; */
    margin-right: ${(props) => (props.lastCheck ? "0px" : `5px`)};
  }
`;

const BarNews = styled.div`
  width: 30px;
  height: 4px;
  background: #ffffff;
  border-radius: 2px;
  margin-bottom: 30px;
  transition: 0.5s;

  ${NewsWrapper}:hover & {
    width: 135px;
    transition: 0.5s;

    @media ${(props) => props.theme.mobile} {
      width: 80px;
    }
  }
`;

const TitleNews = styled.div`
  /* width: 270px; */
  width: ${(props) => `${props.newsWidth}px`};
  height: 96px;
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  color: #ffffff;

  @media ${(props) => props.theme.tablet} {
    font-size: ${(props) => `${props.mobileTitleNewsFontSize}px`};
    line-height: ${(props) => `${props.mobileTitleNewsLineHeight}px`};
    width: ${(props) => `${props.newsWidthTM}px`};
    height: ${(props) => `${props.newsHeightTM}px`};
  }

  @media ${(props) => props.theme.mobile} {
    width: 158px;
    height: 72px;
    font-size: 16px;
    line-height: 24px;
  }
`;

const InfoNews = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  color: ${colors.G4};
`;

const NameInfo = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const DateInfo = styled.div`
  margin-left: 10px;
  font-size: 14px;
  font-weight: 400;
`;
