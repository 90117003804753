import { Redirect } from "react-router-dom";

// components
import About from "../pages/about";
import Event from "../pages/event";
import Service from "../pages/service";

export const homeRoute = [
  { path: "/event", component: Event },
  { path: "/about", component: About },
  { path: "/", component: Service },
  { path: "/", component: () => <Redirect to="/" /> },
];
